import React from "react";
import { Button, Input, Label } from "semantic-ui-react";
import { useQueryParams } from "use-query-params";
import qs from "qs";

export const PageNavigationDetailed = ({
  previous,
  next,
  pageNumber,
  totalPages,
}) => {
  const [pq, pSetQuery] = useQueryParams({});
  const [nq, nSetQuery] = useQueryParams({});
  return (
    <Input size={"mini"} type={"text"}>
      <Button
        disabled={!previous}
        icon="left arrow"
        onClick={(e) => {
          if (previous) {
            const p = qs.parse(previous);
            pSetQuery({ ...p }, "replace");
          }
        }}
      />
      <Label basic>
        {t("common.page")} {`${pageNumber}`} {t("common.page_of")} {totalPages}
      </Label>
      <Button
        disabled={!next}
        icon="right arrow"
        onClick={(e) => {
          if (next) {
            const n = qs.parse(next);
            nSetQuery({ ...n }, "replace");
          }
        }}
      />
    </Input>
  );
};

export const PageNavigationSimple = ({ previous, next }) => {
  const [pq, pSetQuery] = useQueryParams({});
  const [nq, nSetQuery] = useQueryParams({});
  return (
    <div>
      <Button
        disabled={!previous}
        icon="left arrow"
        size={"mini"}
        onClick={(e) => {
          if (previous) {
            const p = qs.parse(previous);
            pSetQuery({ ...p }, "replace");
          }
        }}
      />
      <Button
        disabled={!next}
        icon="right arrow"
        size={"mini"}
        onClick={(e) => {
          if (next) {
            const n = qs.parse(next);
            nSetQuery({ ...n }, "replace");
          }
        }}
      />
    </div>
  );
};

export const determinePage = (meta) => {
  let next, previous, currentPage, totalPages;

  if (meta.next) {
    next = meta.next.split("?")[1];
    currentPage = parseInt(meta.next.match(/page=\d/gm)[0].split("=")[1]) - 1;
  } else {
    currentPage = 1;
  }
  if (meta.prev) {
    if (meta.prev.includes("?")) {
      previous = meta.prev.split("?")[1];
      previous.match(/page=\d/gm)
        ? (currentPage = parseInt(previous.split("=")[1]) + 1)
        : (currentPage = 2);
    } else {
      previous = "page=1";
      currentPage = 2;
    }
  }
  meta.count <= 25
    ? (totalPages = 1)
    : (totalPages =
        meta.count % 25 ? Math.ceil(meta.count / 25) : meta.count / 25);
  return [next, previous, currentPage, totalPages];
};

/* @flow */
import React from "react";
import { Icon, Label, List, Popup, Segment } from "semantic-ui-react";

import BlockEdit from "../edit";
import { RoleRequired } from "../../auth/role-required";
import { BlockConfigDash } from "../../../components/block/block-config-dash";
import { TicketEdit } from "../../ticket/edit";

import { reverse, routes } from "../../../routes";
import styled from "styled-components";
import { permissions } from "../../../constant/permissions";
import { t } from "../../../constant/translations";

type Props = {
  block: Object,
  history: Object,
};

const StyledLabel = styled(Label)`
  float: right;
  margin-right: 7px !important;
`;

const StyledListHeader = styled(List.Header)`
  padding-left: 4px;
`;

const StyledList = styled(List)`
  div[role="listitem"] > div {
    min-height: 24px;
  }
`;

export class DetailsSummary extends React.Component<Props> {
  render() {
    const { block, history } = this.props;
    const landlord = block.landlord;
    const goTo = (url) => {
      return () => {
        return history.push(url);
      };
    };

    return (
      <Segment.Group>
        <Segment>
          <RoleRequired admin={true} permission={permissions.blockEdit}>
            <BlockEdit entity={block} />
          </RoleRequired>
          <div>
            {landlord.name}
            <br />
            <a href={`mailto:${landlord.email}`}>{landlord.email}</a>
          </div>
        </Segment>
        <Segment style={{ padding: "5px 0px" }}>
          <StyledList size={"large"} relaxed selection>
            <List.Item
              onClick={goTo(
                reverse(routes.blockEstateList, { blockId: block.id })
              )}
            >
              <List.Content>
                <StyledListHeader forwardedAs="a">
                  <Icon name={"building"} /> {t("side.properties")}{" "}
                  <StyledLabel size={"small"}>{block.estates}</StyledLabel>
                </StyledListHeader>
              </List.Content>
            </List.Item>
            <RoleRequired staff={true} permission={permissions.customerView}>
              <List.Item
                onClick={goTo(
                  reverse(routes.blockProfiles, { blockId: block.id })
                )}
              >
                <List.Content>
                  <StyledListHeader forwardedAs="a">
                    <Icon name={"users"} /> {t("block.clients")}{" "}
                  </StyledListHeader>
                </List.Content>
              </List.Item>
            </RoleRequired>
            <List.Item
              onClick={goTo(reverse(routes.blockBudget, { blockId: block.id }))}
            >
              <List.Content>
                <StyledListHeader forwardedAs="a">
                  <Icon name={"euro sign"} /> {t("side.accounting")}
                </StyledListHeader>
              </List.Content>
            </List.Item>
            <List.Item
              onClick={goTo(
                reverse(routes.blockTickets, { blockId: block.id })
              )}
            >
              <List.Content>
                <StyledListHeader forwardedAs="a">
                  <Icon name={"bell"} /> {t("side.signals")}
                </StyledListHeader>
              </List.Content>
            </List.Item>
          </StyledList>
        </Segment>

        <RoleRequired staff={true} permission={permissions.blockEdit}>
          <Segment textAlign={"center"}>
            <BlockConfigDash block={block} />
          </Segment>
        </RoleRequired>
        <Segment>
          <Popup
            content={t("block.signals_info")}
            position={"bottom center"}
            trigger={
              // NOTE: Trigger should be wrapped in DOM element, as it is also a popup.
              <div>
                <TicketEdit
                  block={block}
                  trigger_props={{
                    fluid: true,
                    content: t("block.signal"),
                    color: "green",
                    icon: false,
                    size: "medium",
                    basic: false,
                  }}
                />
              </div>
            }
          />
        </Segment>
      </Segment.Group>
    );
  }
}

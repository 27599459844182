import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";

import { t } from "../../constant/translations";
import { NumberParam, StringParam } from "use-query-params";

export const transactionQueryFilterParams = {
  q: StringParam,
  transaction_date: NumberParam,
  transaction_type: StringParam,
};

export const transactionQueryKeyMap = {
  q: t("charge.search"),
  transaction_type: (value) =>
    `${t("labels.type_lowerc")}: ${t(
      `transactionTypes.${value}`
    ).toLowerCase()}`,
};

export const ContentFilterFormBody = ({ data, onChange }) => {
  return (
    <Fragment>
      <Form.Input
        icon="search"
        placeholder={t("charge.search_capital")}
        onChange={onChange("q")}
        value={data.q || ""}
        name={"search-transaction"}
      />
      <h5>{t("billing.from_type")}</h5>
      <Form.Select
        value={data.transaction_type || null}
        options={[null, "expense", "income"].map((e) => {
          return { text: t(`transactionTypes.${e}`), value: e };
        })}
        onChange={onChange("transaction_type")}
      />
    </Fragment>
  );
};

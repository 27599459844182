// @flow
import * as React from "react";
import { ConfirmModalAction } from "../../components/common/confirm-modal";
import { apirequest } from "../../helpers/api-client";
import { t } from "../../constant/translations";
import { Message } from "semantic-ui-react";

type Props = {
  estate: any,
  disabled: ?boolean,
};

export class PayByCardAction extends React.Component<Props> {
  state = {
    loading: false,
    formContent: null,
  };

  onAction = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    const result = await apirequest(
      "post",
      `/api/checkout/init/`,
      this.props.estate
    );
    if (result.body.url) {
      window.location.replace(result.body.url);
    }
  };

  render() {
    const estate = this.props.estate;

    const content = (
      <>
        <p>
          {t("estate.proceed_forward_stripe")} {estate.debt}{" "}
          {t("resources.bgn")}
        </p>

        <Message
          warning={true}
          icon={"warning"}
          content={t("component.pay-by-card.transaction_fee_info")}
        />
      </>
    );
    return (
      <ConfirmModalAction
        header={`${t("estate.payment_to")} ${estate.name}`}
        content={content}
        trigger_props={{
          size: "tiny",
          positive: true,
          content: t("estate.pay"),
          disabled: this.props.disabled,
        }}
        error={null}
        loading={this.state.loading}
        action={this.onAction}
        confirmLabel={t("labels.proceed")}
      />
    );
  }
}

import i18next from "i18next";
import { translationResource } from "./translation/total";

i18next.init({
  fallbackLng: "en",
  lng: "en",
  resources: translationResource,
});

/*
DUPLICATES:

Банков превод: paymentMethods.bank, paymentTypes.bank

Без значение: paymentMethods.null, charge.no_matter, chargeStatus.null

В брой: paymentMethods.cash, paymentTypes.cash

Всички: ticketStates.all, logActions.null, paymentTypes.null, resources.null, ticketTypes.null, transactionTypes.null

Изтрий: userActions.delete, labels.delete

Модел на таксуване: billing.payment_model, charge.billing_model

Общи: transactionTypes.general, file.common

Отговорник: ticketUserTypes.assignee, labels.assignee

Очаква плащане: chargeStatus.unsettled, invoiceStates.pending

Паркомясто: estateTypes.parking_space, estateTypes.parking space

Платено: chargeStatus.settled, settlement.paid

Плащане Номер: labels.payment_number, settlement.payment_number

плащане: resources.settlement, resources.checkout

потребител: roles.user, resources.user

Промяна на: billing.change_of, fund.change_of

трансакция: resources.transaction, resources.fund-transaction
*/
/**
 * Get translation for provided path or error if path does not exists.
 *
 * @example
 *
 *   t('labels.title');
 */
export function t(path, defaultString = null) {
  const currLanguage = localStorage.getItem("current--language");
  const result = i18next.t(path, { lng: currLanguage || "bg-BG" });
  if (!result) {
    if (defaultString != null) {
      return defaultString;
    }
    throw new Error(`No translation for path: ${path}`);
  }

  return result;
}

/**
 * Get translation for provided path, translate it and treat it as a template for provided params
 *
 * @example
 *  tp('base.template_with_params`, {param1: "some", param2:"another"})
 *  // base.template_with_params: "Replace ${param1} param with ${param}"
 *  // -> Replace some param with another
 */
export function tp(path, params) {
  const translatedTemplate = t(path);
  const transFunc = new Function(
    "params",
    "return `" + translatedTemplate + "`;"
  );

  return transFunc(params);
}

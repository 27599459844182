import Tour from "reactour";
import React, { useState } from "react";
import {
  Button,
  Grid,
  GridColumn,
  GridColumn as Col,
  GridRow,
  GridRow as Row,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";

import Notes from "../note/notes";
import Board from "../board/board";
import BlockFundSummary from "./segments/block-fund-summary";
import EstateOwnerSummary from "../estate/owner-summary";

import { FileBox } from "../file/filebox";
import { BlockInfo } from "./segments/info";
import { RoleRequired } from "../auth/role-required";
import { DetailsSummary } from "./segments/details-summary";
import { BillingReports } from "./segments/billing-reports-list";
import { t } from "../../constant/translations";

import styled from "styled-components";
import { permissions } from "../../constant/permissions";
import { reverse, routes } from "../../routes";
import { bindActionCreators } from "redux";
import { deleteBlock } from "../../redux/modules/blocks";
import { DeleteModal } from "../../components/common/delete-modal";

const steps = [
  {
    selector: "#tour--management",
    content: t("tour.management"),
  },
  // {
  //   selector: "#tour--charges",
  //   content: t("tour.charges"),
  // },
  // {
  //   selector: "#tour--reports",
  //   content: "Не се вижда от клиентите и не ни трябва описание."
  // },
  {
    selector: "#tour--notes",
    content: t("tour.notes"),
  },
  {
    selector: "#tour--messages",
    content: t("tour.messages"),
  },
  {
    selector: "#tour--info",
    content: t("tour.info"),
  },
  // {
  //   selector: "#tour--funds",
  //   content: "Не се вижда от клиентите и не ни трябва описание."
  // },
  {
    selector: "#tour--docs",
    content: t("tour.docs"),
  },
];

const sectionSegmentStyle = `
  margin: 0 0 10px 0 !important;
`;

const StyledNotes = styled(Notes)`
  ${sectionSegmentStyle}
`;
const StyledDetailsSummary = styled(DetailsSummary)`
  ${sectionSegmentStyle}
`;
const StyledBillingReports = styled(BillingReports)`
  ${sectionSegmentStyle}
`;
const StyledBoard = styled(Board)`
  ${sectionSegmentStyle}
`;
const StyledBlockInfo = styled(BlockInfo)`
  ${sectionSegmentStyle}
`;
const StyledBlockFundSummary = styled(BlockFundSummary)`
  ${sectionSegmentStyle}
`;
const StyledFileBox = styled(FileBox)`
  ${sectionSegmentStyle}
`;

const StyledHeader = styled(Header)({
  margin: "15px 0 5px 15px !important",
});

function BlockDetails(props) {
  const [confirmVisible, setConfirmVisible] = useState(
    !window.localStorage.getItem("--tour--message--viewed")
  );
  const [tourVisible, setTourVisible] = useState(false);

  return (
    <React.Fragment>
      {confirmVisible && (
        <Segment basic secondary style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Message
            info
            onDismiss={() => {
              setConfirmVisible(false);
              window.localStorage.setItem("--tour--message--viewed", true);
            }}
            header={t("block.welcome")}
            content={
              <React.Fragment>
                <p>{t("block.info_functionality")}</p>
                <Button primary onClick={() => setTourVisible(true)}>
                  {t("block.enter")}
                </Button>
              </React.Fragment>
            }
          />
        </Segment>
      )}

      <Grid padded reversed="tablet" columns={3}>
        {!props.block.active && (
          <Row>
            <Col tablet={16}>
              <Message error icon={"trash"}>
                <Message.Header>{t("block.object_archived")}</Message.Header>
                <Message.Content>
                  <DeleteModal
                    entity={props.block}
                    del={props.del}
                    floated={"right"}
                  />
                </Message.Content>
              </Message>
            </Col>
          </Row>
        )}
        <Row>
          <Col computer={6} tablet={16}>
            {tourVisible && (
              <Tour
                steps={steps.filter(
                  (s) => !!document.querySelector(s.selector)
                )}
                isOpen={tourVisible}
                onRequestClose={() => setTourVisible(false)}
              />
            )}
            <div id="tour--management">
              <StyledHeader forwardedAs="h3">
                {t("side.management")}
              </StyledHeader>
              <StyledDetailsSummary
                block={props.block}
                history={props.history}
              />
            </div>

            <EstateOwnerSummary block={props.block} />
            <RoleRequired userOnly={true}>
              <div id="tour--reports">
                <StyledHeader forwardedAs="h3">
                  {t("billing.reports")}
                </StyledHeader>
                <StyledBillingReports block={props.block} />
              </div>
            </RoleRequired>
            <RoleRequired staff={true} permission={permissions.notesView}>
              <div id="tour--notes">
                <StyledHeader forwardedAs="h3">{t("block.notes")}</StyledHeader>
                <StyledNotes entity={props.block} entityType="block" />
              </div>
            </RoleRequired>
          </Col>
          <Col computer={10} tablet={16}>
            <div id="tour--messages">
              <StyledHeader forwardedAs="h3">
                {t("block.messages")}
              </StyledHeader>
              <StyledBoard block={props.block} />
            </div>
            <div id="tour--info">
              <StyledHeader forwardedAs="h3">{t("block.info")}</StyledHeader>
              <StyledBlockInfo block={props.block} />
            </div>
            <RoleRequired
              staff={true}
              permission={permissions.billingAccountViewBalance}
            >
              <div id="tour--funds">
                <StyledHeader forwardedAs="h3">
                  {t("billing.tills")}
                </StyledHeader>
                <StyledBlockFundSummary block={props.block} />
              </div>
            </RoleRequired>

            <div id="tour--docs">
              <StyledHeader forwardedAs="h3">
                {t("block.documents")}
              </StyledHeader>
              <StyledFileBox block={props.block} />
            </div>
          </Col>
        </Row>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const block = state.blocks.selected;
  return {
    block: block,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const del = (data) => (dispatch) => {
    dispatch(deleteBlock(data)).then(() => {
      ownProps.history.push(reverse(routes.blockList));
    });
  };
  return bindActionCreators({ del }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockDetails);

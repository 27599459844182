import { newApi, defaultInitialState } from "../restdux/index";

const LOGOUT_ALL = "app/auth/LOGOUT_ALL";
const LOGOUT_ALL_FAIL = "app/auth/LOGOUT_ALL_FAIL";
const LOGOUT_ALL_SUCCESS = "app/auth/LOGOUT_ALL_SUCCESS";

export const userManager = newApi("user", "/api/user/");
export const reducer = (oldState = defaultInitialState, action = {}) => {
  let state = userManager.reducer(oldState, action);
  switch (action.type) {
    case LOGOUT_ALL:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case LOGOUT_ALL_FAIL:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case LOGOUT_ALL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
};

export type User = {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  is_active: boolean,
  has_active_email: boolean,
  last_login: ?string,
  role: "admin" | "staff" | "user",
};

export function fetchUsers() {
  return userManager.actions.list();
}

export function createUser(data) {
  return userManager.actions.create(data);
}

export function updateUser(data) {
  return userManager.actions.update(data);
}

export function deleteUser(data) {
  return userManager.actions.update({
    ...data,
    is_active: false,
  });
}

export function removeActiveSessions(u) {
  return {
    types: [LOGOUT_ALL, LOGOUT_ALL_SUCCESS, LOGOUT_ALL_FAIL],
    promise: (client) =>
      client.post(`/api/user/${u.id}/remove_active_sessions/`, {
        data: {
          user: u,
        },
      }),
  };
}

export const clearError = userManager.actions.clearError;

import styled from "styled-components";
import * as React from "react";
import { Button, Segment } from "semantic-ui-react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { Remarkable } from "remarkable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBlock } from "../../../redux/modules/blocks";
import { ErrorWrap } from "../../../helpers/error";
import { RoleRequired } from "../../auth/role-required";
import { permissions } from "../../../constant/permissions";
import { t } from "../../../constant/translations";

const md = new Remarkable();

type Props = {
  block: Object,
  updateBlock: Function,
  loading: Boolean,
  error: Object,
};

const StyledSegment = styled(Segment)`
  min-height: 80px;
`;

export class BlockInfoComponent extends React.Component<Props> {
  state = { editorState: EditorState.createEmpty(), editorOpen: false };

  componentDidMount() {
    this.setState({ editorState: this.getEditorState() });
  }

  componentDidUpdate(oldProps) {
    if (oldProps !== this.props) {
      if (this.props.loading || this.props.error.exists()) {
        return;
      }
      this.setState({ editorState: this.getEditorState(), editorOpen: false });
    }
  }

  getEditorState = () => {
    const blockInfo = this.props.block.config.info;
    const content = convertFromRaw(markdownToDraft(blockInfo));
    return EditorState.createWithContent(content);
  };

  getMarkdown = () => {
    return draftToMarkdown(
      convertToRaw(this.state.editorState.getCurrentContent()),
      {
        remarkableOptions: {
          preserveNewlines: true,
        },
      }
    );
  };

  updateBlockInfo = () => {
    this.props.block.config.info = this.getMarkdown();
    this.props.updateBlock(this.props.block);
  };

  render() {
    const content = this.getMarkdown();

    return (
      <StyledSegment>
        {this.state.editorOpen && (
          <div>
            {this.props.error.exists() && this.props.error.getGlobal()}

            <Button
              floated="right"
              icon={"close"}
              size={"tiny"}
              basic={true}
              disabled={this.props.loading}
              onClick={() => this.setState({ editorOpen: false })}
            />
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorStyle={{
                borderRadius: "2px",
                border: "1px solid #f1f1f1",
                padding: "6px 5px 0",
                marginBottom: "5px",
              }}
              editorState={this.state.editorState}
              onEditorStateChange={(editorState) => {
                this.setState({ editorState });
              }}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "list",
                  "link",
                  "emoji",
                  "remove",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
            <Button
              loading={this.props.loading}
              onClick={() => this.updateBlockInfo()}
            >
              {t("labels.save")}
            </Button>
          </div>
        )}
        {!this.state.editorOpen && (
          <div>
            <RoleRequired staff={true} permission={permissions.boardEdit}>
              <Button
                floated="right"
                icon={"edit"}
                size={"tiny"}
                basic={true}
                onClick={() => this.setState({ editorOpen: true })}
              />
            </RoleRequired>
            <div dangerouslySetInnerHTML={{ __html: md.render(content) }} />
          </div>
        )}
      </StyledSegment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.blocks.pending,
    error: ErrorWrap.fromError(state.blocks.error),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateBlock,
    },
    dispatch
  );
}

export const BlockInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockInfoComponent);

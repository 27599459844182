import styled from "styled-components";
import * as React from "react";

import { connect } from "react-redux";
import { Dropdown, Segment } from "semantic-ui-react";
import dayjs from "dayjs";
import { DownloadLink } from "../../../helpers/download-link";
import { entityGet, entityGetByID } from "../../../helpers/filter";
import { t } from "../../../constant/translations";

const StyledReportList = styled.div`
  display: flex;
  & > :last-child {
    margin-left: 10px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  flex-grow: 1;
`;

class BillingReportsComponent extends React.Component {
  state = {
    selected: null,
  };

  componentDidMount() {
    if (this.props.reports) {
      this.setState({ selected: this.props.reports[0] });
    }
  }

  getDate = (report) => {
    let reportDate = dayjs(report.date).format("YYYY/MM");
    if (reportDate === "1970-01") {
      reportDate = t("billing.old_period");
    }
    return reportDate;
  };

  getLink = (report) => {
    return `/api/billing-monthly-report/${report.id}/export/?format=pdf`;
  };

  getFilename = (report) => {
    return `${report.block_name}-${dayjs(report.created_at).format(
      "YYYY/MM"
    )}.pdf`;
  };

  getReportOptions = (reports) => {
    return reports.map((report) => {
      const reportDate = this.getDate(report);
      return {
        text: `${t("block.report_to")} ${reportDate}`,
        value: report.id,
      };
    });
  };

  render() {
    const { reports } = this.props;
    if (!this.state.selected) {
      return <Segment content={t("block.no_reports")} />;
    }
    return (
      <Segment>
        <StyledReportList id="noto">
          <StyledDropdown
            options={this.getReportOptions(reports)}
            onChange={(e, { value }) => {
              this.setState({ selected: entityGetByID(reports, value) });
            }}
            scrolling
            fluid
            selection
            value={this.state.selected.id}
          />
          <DownloadLink
            href={this.getLink(this.state.selected)}
            download={this.getFilename(this.state.selected)}
            icon={"download"}
            size={"small"}
          />
        </StyledReportList>
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: state.billingMonthlyReport.entities,
  };
}

export const BillingReports = connect(mapStateToProps)(BillingReportsComponent);
